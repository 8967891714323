import * as types from '../actionTypes'

const initialState = {
    bannerDisplayed: true
}

function otherReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLOSE_TOP_BANNER:
            return { ...state, bannerDisplayed: false }
        default:
            return state
    }
}

export default otherReducer