import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ROBOT_META } from '../config';
import MetaTags from 'react-meta-tags';

const SEO = ({ specificToPage }) => {

    const partnerTypeState = useSelector(state => state.partnerTypeState);

    return (
        <MetaTags>
            <title>{specificToPage.title}</title>

            {specificToPage.description && <meta name="description" content={specificToPage.description} />}
            {specificToPage.keywords && <meta name="keywords" content={specificToPage.keywords} />}
            {specificToPage.canonical && <link rel="canonical" href={partnerTypeState.partnerInfo.prodUrl + specificToPage.canonical} />}
            {ROBOT_META && <meta name="robots" content={ROBOT_META} />}
            <meta name="distribution" content="global" />

            {specificToPage.googleSiteVerification && <meta name="google-site-verification" content={specificToPage.googleSiteVerification} />}
            {specificToPage.msvalidate && <meta name="msvalidate.01" content={specificToPage.msvalidate} />}

            <meta httpEquiv="content-language" content="en-us" />

            {specificToPage.canonical && <meta name="og:url" content={partnerTypeState.partnerInfo.prodUrl + specificToPage.canonical}/>}
            {specificToPage.title && <meta name="og:title" content={specificToPage.title} />}
            {specificToPage.description && <meta name="og:description" content={specificToPage.description} />}

            {specificToPage.ogImage && <meta name="og:image" content={specificToPage.ogImage} />}

            <meta property="og:type" content="website" />

            {specificToPage.title && <meta name="twitter:title" content={specificToPage.title} />}
            {specificToPage.description && <meta name="twitter:description" content={specificToPage.description} />}
            {specificToPage.canonical && <meta name="twitter:url" content={partnerTypeState.partnerInfo.prodUrl + specificToPage.canonical} />}

            {specificToPage.twitterImage && <meta name="twitter:image" content={specificToPage.twitterImage} />}

            {specificToPage.twitterCard && <meta name="twitter:card" content={specificToPage.twitterCard} />}
            {specificToPage.twitterSite && <meta name="twitter:site" content={specificToPage.twitterSite} />}
            {specificToPage.twitterCreator && <meta name="twitter:creator" content={specificToPage.twitterCreator} />}
            {/* {commonToSite.distribution && <meta name="distribution" content={commonToSite.distribution} />} */}
            <meta name="distribution" content="global" />

            {specificToPage.prev && <link rel="prev" href={specificToPage.prev} />}
            {specificToPage.next && <link rel="next" href={specificToPage.next} />}

        </MetaTags>
    )
}

export default SEO
