import * as types from '../actionTypes'
import partners from '../../data/partners.json';

const initialState = {
    partnerType: 'shop',
    partnerInfo: {}
}


function partnerTypeReducer(state = initialState, action) {

    switch (action.type) {
        case types.SET_PARTNER_TYPE:
            return { partnerType: action.payload, partnerInfo: getPartnerInfo(action.payload) }
        default:
            return state
    }
}

const getPartnerInfo = partnerType => {
    let indexOfFoundPartnerObj = partners.findIndex(partner => partner.name === partnerType)
    return partners[indexOfFoundPartnerObj]
}

export default partnerTypeReducer