import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import store from "./redux/store";
import * as fetchCoursesActions from './redux/actions/fetchCoursesActions';
import * as fetchCurrencyExchangeRates from './redux/actions/fetchCurrencyExchangeRates';
import * as partnerTypeActions from './redux/actions/partnerTypeActions';

store.dispatch(partnerTypeActions.setPartnerType(window.PARTNER_TYPE));
store.dispatch(fetchCoursesActions.fetchShopifyCourses(window.PARTNER_TYPE));
store.dispatch(fetchCurrencyExchangeRates.fetchCurrencyExchangeRates());


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
