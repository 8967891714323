// fetch courses from shopify
export const FETCH_SHOPIFY_COURSES_REQUEST = "FETCH_SHOPIFY_COURSES_REQUEST";
export const FETCH_SHOPIFY_COURSES_SUCCESS = "FETCH_SHOPIFY_COURSES_SUCCESS";
export const FETCH_ALL_SHOPIFY_COURSES_SUCCESS = "FETCH_ALL_SHOPIFY_COURSES_SUCCESS";
export const FETCH_SHOPIFY_COURSES_FAILURE = "FETCH_SHOPIFY_COURSES_FAILURE";

// fetch courses from recharge
export const FETCH_RECHARGE_COURSES_REQUEST = "FETCH_RECHARGE_COURSES_REQUEST";
export const FETCH_RECHARGE_COURSES_SUCCESS = "FETCH_RECHARGE_COURSES_SUCCESS";
export const FETCH_RECHARGE_COURSES_FAILURE = "FETCH_RECHARGE_COURSES_FAILURE";

// fetch courses detail from CMS
export const FETCH_COURSE_DETAIL_REQUEST = "FETCH_COURSE_DETAIL_REQUEST";
export const FETCH_COURSE_DETAIL_SUCCESS = "FETCH_COURSE_DETAIL_SUCCESS";
export const FETCH_COURSE_DETAIL_FAILURE = "FETCH_COURSE_DETAIL_FAILURE";

// fetch courses detail from CMS
export const FETCH_CURRENCY_EXCHANGE_RATES_REQUEST = "FETCH_CURRENCY_EXCHANGE_RATES_REQUEST";
export const FETCH_CURRENCY_EXCHANGE_RATES_SUCCESS = "FETCH_CURRENCY_EXCHANGE_RATES_SUCCESS";
export const FETCH_CURRENCY_EXCHANGE_RATES_FAILURE = "FETCH_CURRENCY_EXCHANGE_RATES_FAILURE";
export const SET_SELECTED_CURRENCY = "SET_SELECTED_CURRENCY";

// linked auth 
export const LINKEDIN_SIGNUP_REQUEST = "LINKEDIN_SIGNUP_REQUEST";
export const LINKEDIN_SIGNUP_SUCCESS = "LINKEDIN_SIGNUP_SUCCESS";
export const LINKEDIN_SIGNUP_FAILURE = "LINKEDIN_SIGNUP_FAILURE";


// list card view 
export const SET_LIST_VIEW = "SET_LIST_VIEW";
export const SET_CARD_VIEW = "SET_CARD_VIEW";
export const TOGGLE_VIEW_IMAGE = "TOGGLE_VIEW_IMAGE";
export const TOGGLE_FUNC_ROLE_SELECTION = "TOGGLE_FUNC_ROLE_SELECTION";
export const TOGGLE_IS_MEMBER_COURSES = "TOGGLE_IS_MEMBER_COURSES";

export const TOGGLE_All_FUNC_ROLE_SELECTION = "TOGGLE_All_FUNC_ROLE_SELECTION";
export const TOGGLE_SUBSCRIPTION_COURSE_DISPLAY = "TOGGLE_SUBSCRIPTION_COURSE_DISPLAY";
export const TOGGLE_COMPANION_COURSE_DISPLAY = "TOGGLE_COMPANION_COURSE_DISPLAY";

export const SET_DISPLAYED_FUNC_ROLES = "SET_DISPLAYED_FUNC_ROLES";

export const ADD_TO_CART = "ADD_TO_CART";
export const CHANGE_QTY = "CHANGE_QTY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_ALL_FROM_CART = "REMOVE_ALL_FROM_CART";


export const FILTER_COURSE = "FILTER_COURSE";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const LOGOUT = "LOGOUT";


export const SET_PARTNER_TYPE = "SET_PARTNER_TYPE";

export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";

export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";

export const RESETPASSWORD_REQUEST = "RESETPASSWORD_REQUEST";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

export const USERPROFILE_REQUEST = "USERPROFILE_REQUEST";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";
export const USERPROFILE_FAILURE = "USERPROFILE_FAILURE";

export const MYCOURSELIST_REQUEST = "MYCOURSELIST_REQUEST";
export const MYCOURSELIST_SUCCESS = "MYCOURSELIST_SUCCESS";
export const MYCOURSELIST_FAILURE = "MYCOURSELIST_FAILURE";

export const SUBSCRIBEDCRS_REQUEST = "SUBSCRIBEDCRS_REQUEST";
export const SUBSCRIBEDCRS_SUCCESS = "SUBSCRIBEDCRS_SUCCESS";
export const SUBSCRIBEDCRS_FAILURE = "SUBSCRIBEDCRS_FAILURE";

export const PURCHASEDCRS_REQUEST = "PURCHASEDCRS_REQUEST";
export const PURCHASEDCRS_SUCCESS = "PURCHASEDCRS_SUCCESS";
export const PURCHASEDCRS_FAILURE = "PURCHASEDCRS_FAILURE";

export const USERCOURSESEAT_REQUEST = "USERCOURSESEAT_REQUEST";
export const USERCOURSESEAT_SUCCESS = "USERCOURSESEAT_SUCCESS";
export const USERCOURSESEAT_FAILURE = "USERCOURSESEAT_FAILURE";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const CLEARPURCHASESTATE = "CLEARPURCHASESTATE";
export const CLEARMYCOURSESTATE = "CLEARMYCOURSESTATE";
export const CLEARSUBSCRIBEDCRSSTATE = "CLEARSUBSCRIBEDCRSSTATE";
export const CLEARUSRCRSMAPSTATE = "CLEARUSRCRSMAPSTATE";
export const CLEARFORGOTPSWDSTATE = "CLEARFORGOTPSWDSTATE";
export const UPDATEUSERPROFILE_DTLS = "UPDATEUSERPROFILE_DTLS";

// Others
export const CLOSE_TOP_BANNER = "CLOSE_TOP_BANNER";