import * as types from '../actionTypes'

const initialState = {
    currencyExchangeRateObj: [],
    selectedCurrency: "USD",
    allCurrencyCodes: [
        {
            code: "USD",
            displayedName: "Dollars",
            symbol: "$",
        },
        {
            code: "GBP",
            displayedName: "British Pounds",
            symbol: "£",
        },
        {
            code: "EUR",
            displayedName: "Euros",
            symbol: "€",
        }
    ],
    loading: false,
    apiSuccess: false
}

function fetchCurrencyExchangeRatesReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_CURRENCY_EXCHANGE_RATES_REQUEST:
            return { ...state, loading: true }
        case types.FETCH_CURRENCY_EXCHANGE_RATES_SUCCESS:
            return { ...state, currencyExchangeRateObj: action.payload, loading: false, apiSuccess: true }
        case types.FETCH_CURRENCY_EXCHANGE_RATES_FAILURE:
            return { ...state, currencyExchangeRateObj: [], apiSuccess: false, loading: false }
        case types.SET_SELECTED_CURRENCY:
            return { ...state, selectedCurrency: action.payload }
        default:
            return state
    }
}


export default fetchCurrencyExchangeRatesReducer