import { combineReducers } from 'redux';

import filterViewReducer from './filterViewReducer';
import fetchCoursesReducer from './fetchCoursesReducer';
import fetchAllCoursesReducer from './fetchAllCoursesReducer';
import fetchCourseDetailReducer from './fetchCourseDetailReducer';

import cartReducers from './cartReducers';
import courseFilterReducer from './courseFilterReducer';
import authReducer from './authReducer';
import signUpReducer from './signUpReducer';
import partnerTypeReducer from './partnerTypeReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import userProfileReducer from './userProfileReducer';
import myCourseListingReducer from './myCourseListingReducer';
import subscribedCoursesReducer from './subscribedCoursesReducer';
import purchasedHistoryReducer from './purchasedHistoryReducer';
import userCourseSeatReducer from './userCourseSeatReducer';
import linkedInAuthReducer from './linkedInAuthReducer';
import fetchCurrencyExchangeRatesReducer from './fetchCurrencyExchangeRatesReducer';
import otherReducer from './otherReducer';


const rootReducer = combineReducers({
    filterViewState: filterViewReducer,
    coursesState: fetchCoursesReducer,
    courseDetailState: fetchCourseDetailReducer,
    cartState: cartReducers,
    courseFilterState: courseFilterReducer,
    authState: authReducer,
    userState: signUpReducer,
    resetPasswordState: resetPasswordReducer,
    forgotPasswordState: forgotPasswordReducer,
    partnerTypeState: partnerTypeReducer,
    userProfileState: userProfileReducer,
    myCoursesState: myCourseListingReducer,
    subscribedCrsState: subscribedCoursesReducer,
    purchasedHistoryState: purchasedHistoryReducer,
    userCrsMapState: userCourseSeatReducer,
    allCoursesState: fetchAllCoursesReducer,
    linkedInAuthState: linkedInAuthReducer,
    currencyExchangeRatesState: fetchCurrencyExchangeRatesReducer,
    otherState: otherReducer

});

export default rootReducer;