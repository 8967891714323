import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import { save, load } from "redux-localstorage-simple";


const store = createStore(
    rootReducer,
    load({ states: ["cartState", "authState", "currencyExchangeRatesState", "otherState"] }),
    // logger, 
    composeWithDevTools(applyMiddleware(thunk, save({ states: ["cartState", "authState", "currencyExchangeRatesState", "otherState"] })))
);

export default store;