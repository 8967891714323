import * as types from "../actionTypes";
import axios from "axios";
import courseGroupAndJurisdictionRelation from '../../data/courseGroupAndJurisdictionRelation.json';
import APIConstants from "../../utils/APIConstants";
import partners from '../../data/partners.json';
import partnerRelation from '../../data/partnerRelation.json';


export const fetchShopifyCoursesRequest = () => {
    return {
        type: types.FETCH_SHOPIFY_COURSES_REQUEST
    }
}

export const fetchShopifyCoursesSuccess = courses => {
    return {
        type: types.FETCH_SHOPIFY_COURSES_SUCCESS,
        payload: courses
    }
}

export const fetchAllShopifyCoursesSuccess = courses => {
    return {
        type: types.FETCH_ALL_SHOPIFY_COURSES_SUCCESS,
        payload: courses
    }
}

export const fetchShopifyCoursesFailure = () => {
    return {
        type: types.FETCH_SHOPIFY_COURSES_FAILURE
    }
}


let allShopifyCourses
let allRechargeCourses

export const fetchShopifyCourses = (partnerType = "shop") => {

    const shopifyReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.SHOPIFY_ALL_PRODUCTS}`);
    // const shopifyReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.SHOPIFY_COURSE_BY_COLLECTION}${partnerType}`);
    const rechargeReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.RECHARGE_ALL_PRODUCTS}`);

    return (dispatch) => {
        dispatch(fetchShopifyCoursesRequest())
        axios.all([shopifyReq, rechargeReq])
            .then(res => {
                dispatch(fetchAllShopifyCoursesSuccess(res[0].data.data.products));
                allShopifyCourses = cleanUpShopifyResponse(res[0].data.data.products)
                allRechargeCourses = cleanUpRechargeResponse(JSON.parse(res[1].data.data).products)
                // console.log('allShopifyCourses', JSON.stringify(allShopifyCourses))
                // console.log('allRechargeCourses', JSON.stringify(allRechargeCourses))

                const filteredCourses = [...filterAndMergeShopifyCourseObjByPartner(partnerType), ...filterAndMergeRechargeCourseObjByPartner(partnerType)]
                dispatch(fetchShopifyCoursesSuccess(filteredCourses))
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchShopifyCoursesFailure())
            })
    }
}

 
const filterAndMergeShopifyCourseObjByPartner = partnerType => {

    let partnerId = partners.filter(item => item.name === partnerType)[0].id;
    let partnerRelFilteredObj = partnerRelation.filter(item => item.partnerId == partnerId);

    let intermediateMerge = [];
    // merge with api
    partnerRelFilteredObj.forEach(item => {
        if (item.courseType === "shopify") {
            let foundIndex = allShopifyCourses.findIndex(innerItem => item.shopifyHandle === innerItem.handle);
            if (foundIndex !== -1)
                intermediateMerge.push({
                    ...item,
                    ...allShopifyCourses[foundIndex],
                    "isMember": allShopifyCourses[foundIndex] != undefined ? checkIfMember(allShopifyCourses[foundIndex].tags) : false
                })
        }
    })

    let finalMerge = [];
    // merge with course rel
    intermediateMerge.forEach(item => {
        let foundIndex = courseGroupAndJurisdictionRelation.findIndex(innerItem => item.courseRelId === innerItem.id && innerItem.collection !== 2)
        if (foundIndex !== -1)
            finalMerge.push({ ...item, ...courseGroupAndJurisdictionRelation[foundIndex] })
    })

    // console.log('finalMerge===>', JSON.stringify(finalMerge))
    return finalMerge
}


const filterAndMergeRechargeCourseObjByPartner = (partnerType) => {

    // console.log('allRechargeCourses', JSON.stringify(allRechargeCourses))

    let partnerId = partners.filter(item => item.name === partnerType)[0].id;
    let partnerRelFilteredObj = partnerRelation.filter(item => item.partnerId == partnerId);
    let intermediateMerge = [];
    // merge partnerRelation with recharge api
    partnerRelFilteredObj.forEach(item => {
        if (item.courseType === "recharge") {
            // console.log("item", item)
            let foundIndex = allRechargeCourses.findIndex(innerItem => item.rechargeHandle === innerItem.handle);
            if (foundIndex !== -1)
                intermediateMerge.push({ ...item, ...allRechargeCourses[foundIndex], rechargeCourseFound: true })
            else
                intermediateMerge.push({ ...item, rechargeCourseFound: false })
        }
    })
// console.log("intermediateMerge", intermediateMerge)
    let intermediateMerge2 = [];
    // merge with course rel
    intermediateMerge.forEach(item => {
        const foundIndex = courseGroupAndJurisdictionRelation.findIndex(innerItem => item.courseRelId === innerItem.id);
        if (foundIndex !== -1)
            intermediateMerge2.push({ ...item, ...courseGroupAndJurisdictionRelation[foundIndex] })
    })

    let finalMerge = [];

    // get recharge subscription price from shopify
    intermediateMerge2.forEach(item => {

        const foundIndexForSubsPrice = allShopifyCourses.findIndex(innerItem => item.rechargeHandle === innerItem.handle);

        const foundIndexForOneTimePrice = allShopifyCourses.findIndex(innerItem => {
            // console.log(innerItem)
            // if (item.shopifyOneTimeHandle !== innerItem.handle) {
            // console.log("item.shopifyOneTimeHandle", item.shopifyOneTimeHandle)
            // console.log("innerItem.handle", innerItem.handle)
            // console.log("")
            // }
            return item.shopifyOneTimeHandle === innerItem.handle
        });

        finalMerge.push({
            ...item,
            "rechargeSubscriptionPrice": allShopifyCourses[foundIndexForSubsPrice] != undefined ? allShopifyCourses[foundIndexForSubsPrice].variants[0].price : '',
            "shopifyOneTimePrice": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? allShopifyCourses[foundIndexForOneTimePrice].variants[0].price : '',
            "shopifyThumbnail": item.rechargeCourseFound === false ? allShopifyCourses[foundIndexForOneTimePrice].image.src : '',
            "CPD": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? getCPDvalue(allShopifyCourses[foundIndexForOneTimePrice].options) : '',
            "isMember": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? checkIfMember(allShopifyCourses[foundIndexForOneTimePrice].tags) : false,
            "optionTitle": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? getValueFromOption(allShopifyCourses[foundIndexForOneTimePrice].options, "title") : '',
        })
    })

    // console.log("finalMerge", JSON.stringify(finalMerge))
    return finalMerge
}

const getCPDvalue = obj => {
    let foundIndex = obj.findIndex(item => item.name === "CPD")
    if (foundIndex != -1)
        return obj[foundIndex].values[0]
    else
        return ''
}

const getValueFromOption = (obj, key) => {
    // console.log("getValueFromOption", obj)
    let titleObj = obj.filter(item => item.name === key)
    if (titleObj.length > 0)
        return titleObj[0].values[0]
}

// helpers
const cleanUpShopifyResponse = (allShopifyCourses) => {
    let tempObj = []
    allShopifyCourses.forEach(item => {
        deleteProps(item, ["body_html", "created_at", "id", "product_type", "published_at", "published_scope", "template_suffix", "updated_at", "vendor", "images", "admin_graphql_api_id"])
        tempObj.push(item)
    })
    return allShopifyCourses
}

const cleanUpRechargeResponse = (allRechargeCourses) => {
    let tempObj = []
    allRechargeCourses.forEach(item => {
        deleteProps(item, ["id", "collection_id", "discount_amount", "created_at", "discount_type"])
        tempObj.push(item)
    })
    return allRechargeCourses
}

const checkIfMember = tagsString => {
    // console.log("tagsString", tagsString)
    if (tagsString.split(',').indexOf(" member") != -1)
        return true
    else
        return false
}

function deleteProps(obj, prop) {
    for (const p of prop) {
        (p in obj) && (delete obj[p]);
    }
}