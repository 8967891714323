import * as types from '../actionTypes';

const initialState = {
    user: [],
    loading: false,
    apiSuccess: undefined,
    isLoggedIn : false
}

function signUpReducer(state = initialState, action) {
    switch (action.type) {
        case types.SIGNUP_REQUEST:
            return { ...state, loading: true }
        case types.SIGNUP_SUCCESS:
            return { user: action, loading: false, apiSuccess: true,isLoggedIn : true }
        case types.SIGNUP_FAILURE:
            return { user: [], apiSuccess: false, loading: false,isLoggedIn : false }
        default:
            return state
    }
}

export default signUpReducer