

import * as types from "../actionTypes";
import axios from "axios";
import APIConstants from "../../utils/APIConstants";

// ref - https://exchangeratesapi.io/

export const fetchCurrencyExchangeRatesRequest = () => {
    return {
        type: types.FETCH_CURRENCY_EXCHANGE_RATES_REQUEST
    }
}

export const fetchCurrencyExchangeRatesSuccess = currencyExchangeRatesObj => {
    return {
        type: types.FETCH_CURRENCY_EXCHANGE_RATES_SUCCESS,
        payload: currencyExchangeRatesObj
    }
}

export const fetchCurrencyExchangeRatesFailure = () => {
    return {
        type: types.FETCH_CURRENCY_EXCHANGE_RATES_FAILURE
    }
}

export const setSelectedCurrency = selectedCurrencyCode => {
    return {
        type: types.SET_SELECTED_CURRENCY,
        payload: selectedCurrencyCode
    }
}

export const fetchCurrencyExchangeRates = () => {
    return (dispatch) => {
        dispatch(fetchCurrencyExchangeRatesRequest())
        axios.get(`${APIConstants.CURRENCY_EXCHANGE_RATES}/latest?symbols=USD,GBP,EUR&base=USD`)
            .then(res => {
                const currencyExchangeRatesObj = res.data.rates;
                dispatch(fetchCurrencyExchangeRatesSuccess(currencyExchangeRatesObj))
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchCurrencyExchangeRatesFailure())
            })
    }
}